import { createAction, props } from '@ngrx/store';
import { FolderEntityTypes } from '@core/models/files-folder.model';

const ActionTypes = {
  CREATE_FOLDER: '[Files folder] create folder',
  UPDATE_FOLDER: '[Files folder] update folder',
  DELETE_FOLDER: '[Files folder] delete folder',
  DELETE_FILE: '[Files folder] delete file',
};

export const createFolder = createAction(
  ActionTypes.CREATE_FOLDER,
  props<{
    parentFolderId: string;
    name: string;
    entityType: FolderEntityTypes;
    entityId: string;
  }>()
);

export const updateFolder = createAction(
  ActionTypes.UPDATE_FOLDER,
  props<{
    folderId: string;
    name: string;
    entityType: FolderEntityTypes;
    entityId: string;
  }>()
);

export const deleteFolder = createAction(
  ActionTypes.DELETE_FOLDER,
  props<{
    folderId: string;
    entityType: FolderEntityTypes;
    entityId: string;
  }>()
);

export const deleteFile = createAction(
  ActionTypes.DELETE_FILE,
  props<{
    filesUrl: string[];
    folderId: string;
    entityType: FolderEntityTypes;
    entityId: string;
  }>()
);
